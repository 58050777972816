import React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel, getLabelAsString } from '../formRenderHelper';

function TextareaInput({ control, handleChange, formOption }) {
    const { name, label, type, value, errors, style } = control;
    const fieldId = utils.random.shortId();
    const fieldLabel = getLabel(control, formOption);
    //const { template = 'bootstrap', usePlaceholder = false } = formOption;
    const { usePlaceholder = false } = formOption;
    const placeHolder = usePlaceholder ? getLabelAsString(control, formOption) : null;
    const valueStr = value === 0 ? 0 : (value || "");


    const textAreaRef = React.useRef(null);
    const [textAreaHeight, setTextAreaHeight] = React.useState("auto");
    const [parentHeight, setParentHeight] = React.useState("auto");

    React.useEffect(() => {
        if (textAreaRef.current) {
            setParentHeight(`${textAreaRef.current.scrollHeight}px`);
            setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
        }
    }, [valueStr]);

    function onChangeInternal(e) {
        setTextAreaHeight("auto");
        if (textAreaRef.current) {
            setParentHeight(`${textAreaRef.current.scrollHeight}px`);
        }
        handleChange(e, control);
    }

    return (
        <div className={`ff ff--textarea form-group ${style && style.className}`}>
            {!usePlaceholder && fieldLabel && <label htmlFor={fieldId} className="label">{fieldLabel}</label>}
            <div className="ff__input" style={{minHeight: parentHeight}}>
                <textarea id={fieldId} name={name} value={valueStr} type={type} onChange={onChangeInternal}
                    placeholder={placeHolder}
                    rows={style && style.rows}
                    ref={textAreaRef}
                    style={{ height: textAreaHeight }}
                    className={"form-control" + (errors && errors.length > 0 ? ' is-invalid' : '')}></textarea>
            </div>
            <ValidationAlert errors={errors} />
        </div>
    )
}

export default TextareaInput;